








































import { Component, Prop, Watch } from 'vue-property-decorator'
import { User as UserModel } from '@technology/accounts-js/dist/types/models/user'
import Vue from 'vue'

import noAvatar from '~/assets/no-avatar.png'

@Component({
  name: 'dashboard-collaborate-common-content-assignee-header',
})
export default class AssigneeHeader extends Vue {
  @Prop()
  public value: string[]
  @Prop()
  public assignees: UserModel[]

  public noAvatar: string = noAvatar
  public selectedItem: string[] = []

  get icon() {
    if (this.selectedAllItems) {
      return 'check_box'
    }
    if (this.selectedSomeItems) {
      return 'indeterminate_check_box'
    }
    return 'check_box_outline_blank'
  }

  get selectedAllItems() {
    return this.selectedItem.length === this.assignees.length
  }

  get selectedSomeItems() {
    return this.selectedItem.length && this.selectedItem.length !== this.assignees.length
  }

  public isSelected(id: string) {
    return this.selectedItem.indexOf(id) >= 0
  }

  public selectAll() {
    this.$nextTick(() => {
      this.selectedAllItems
        ? (this.selectedItem = [])
        : (this.selectedItem = [...this.assignees.map(assignee => assignee.id)])
      this.$emit('change', this.selectedItem)
    })
  }

  @Watch('value')
  public onValueChanged() {
    this.selectedItem = [...this.value]
  }

  public created() {
    this.selectedItem = [...this.value]
  }
}
