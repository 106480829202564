























import { Component, Prop } from 'vue-property-decorator'
import { TaskAssetModel, TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import Vue from 'vue'

import { ItemClass } from '@technology/collaborate-next-js'
import { visible } from '~/directives'
import config from '~/config'
@Component({
  name: 'dashboard-collaborate-common-content-methode-assets-display',
  directives: {
    visible,
  },
})
export default class MethodeTopicItemDisplay extends Vue {
  @Prop()
  public task: TaskModel & object
  @Prop()
  public taskAssets: TaskAssetModel[] & object

  get appConfig() {
    return this.$store.myGetters.getCollaborateAppConfig
  }

  get graphicBtnClass() {
    const classes = []
    if (this.graphicCount === 0) {
      classes.push('c-assets-display__btn--disabled')
    }
    return classes
  }

  get graphicCount() {
    return this.taskAssets.filter(ta => ta.itemType == ItemClass.Graphic).length
  }

  get pictureBtnClass() {
    const classes = []
    if (this.pictureCount === 0) {
      classes.push('c-assets-display__btn--disabled')
    }
    return classes
  }

  get pictureCount() {
    return this.taskAssets.filter(ta => ta.itemType == ItemClass.Photo).length
  }

  public handleBtnClick() {
    const collaborateApp = this.$store.myGetters['accounts/app/currentWorkApps'].find(
      app => app.name === config.appName.collaborate,
    )
    collaborateApp && window.open(`${collaborateApp.url}/locate-task/${this.task.id}`, '_blank')
  }
}
