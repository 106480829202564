
































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { PublishStatusEvent } from '@technology/collaborate-next-js'
import {
  TaskArticleModel,
  TaskArticlePublishStatusModel,
  TaskArticleStatusModel,
  TaskModel,
} from '@technology/collaborate-next-js/dist/types/models'
import { storyStatusList } from '@technology/compose-js'

import { dateFormat, empty } from '~/filters'
import config from '~/config'
import constant from '~/components/dashboard/widget/collaborate/common/constant'
import util from '~/util'
@Component({
  name: 'dashboard-collaborate-common-content-article-display',
  filters: {
    dateFormat,
    empty: empty('-'),
  },
})
export default class ArticleDisplay extends Vue {
  @Prop()
  public article: TaskArticleModel & object
  @Prop()
  public task: TaskModel & object
  @Prop({
    default: () => [],
  })
  public publishStatuses: TaskArticlePublishStatusModel[]
  @Prop({
    default: () => [],
  })
  public articleStatuses: TaskArticleStatusModel[]

  get articleStatus() {
    const statuses = this.articleStatuses.sort((a, b) => util.sortDate(b.createdAt, a.createdAt))
    return statuses?.[0]?.status.replace('SCMP_Stories/', '') ?? ''
  }

  get isShowDivider1() {
    return this.stages.length > 0 && this.scheduledOrCancelledStatus.length > 0
  }

  get isShowDivider2() {
    return (
      (this.stages.length > 0 || this.scheduledOrCancelledStatus.length > 0) &&
      this.publishes.length > 0
    )
  }

  get isPublished() {
    return this.publishes.length > 0
  }

  get isScheduledOrCancelled() {
    return this.scheduledOrCancelledStatus.length > 0
  }

  get isStaged() {
    return this.publishStatuses.find(c => c.event === PublishStatusEvent.Staged)
  }

  get title() {
    return this.article?.name
  }

  get statusColor() {
    if (this.isPublished) {
      return constant.storyColor.isPublish
    } else if (this.isScheduledOrCancelled) {
      const lastStatus = this.scheduledOrCancelledStatus.slice(-1).pop()
      return lastStatus!.event === PublishStatusEvent.Scheduled
        ? constant.storyColor.isSchedule
        : constant.storyColor.isCancelled
    } else if (this.isStaged) {
      return constant.storyColor.isStage
    }
    return storyStatusList.find(s => s.text === this.articleStatus)?.color || ''
  }

  get stages() {
    return this.publishStatuses
      .filter(p => p.event == PublishStatusEvent.Staged)
      .sort((a, b) => util.sortDate(a.updatedAt, b.updatedAt))
  }

  get scheduledOrCancelledStatus() {
    return this.publishStatuses
      .filter(
        p => p.event == PublishStatusEvent.Scheduled || p.event === PublishStatusEvent.Cancelled,
      )
      .sort((a, b) => util.sortDate(a.updatedAt, b.updatedAt))
  }

  get publishes() {
    return this.publishStatuses
      .filter(p => p.event == PublishStatusEvent.Published)
      .sort((a, b) => util.sortDate(a.updatedAt, b.updatedAt))
  }

  get publishStatusDisplay() {
    const statusObj: KeyMap<any> = {
      statusTextStyle: {
        color: this.statusColor,
      },
      updatedAt: undefined,
      statusText: undefined,
      userId: undefined,
      userName: undefined,
    }
    if (this.publishes.length > 0) {
      const pubStatus = this.publishes.slice(-1).pop()
      statusObj.updatedAt = pubStatus?.updatedAt
      statusObj.userId = pubStatus?.userId
      statusObj.statusText = constant.storyStatusText.Published
    } else if (this.scheduledOrCancelledStatus.length > 0) {
      const pubStatus = this.scheduledOrCancelledStatus.slice(-1).pop()
      if (pubStatus?.event === PublishStatusEvent.Scheduled) {
        statusObj.updatedAt = pubStatus?.scheduledPublishedAt
        statusObj.statusText = constant.storyStatusText.Scheduled
      } else {
        statusObj.statusText = constant.storyStatusText.Cancelled
      }
      statusObj.userId = pubStatus?.userId
    } else if (this.stages.length > 0) {
      const pubStatus = this.stages.slice(-1).pop()
      statusObj.updatedAt = pubStatus?.updatedAt
      statusObj.userId = pubStatus?.userId
      statusObj.statusText = constant.storyStatusText.Staged
    }

    return statusObj
  }

  public getUserDisplayNameByUserId(userId: string) {
    return this.$store.myGetters['accounts/user/getById'](userId)?.displayName
  }

  public handleClick() {
    const collaborateApp = this.$store.myGetters['accounts/app/currentWorkApps'].find(
      app => app.name === config.appName.collaborate,
    )

    collaborateApp &&
      window.open(
        `${collaborateApp.url}/groups/${this.$store.myGetters.getGroupName(
          this.task.boardId,
        )}/boards/${this.task.boardId}/${this.task.id}/${this.article.id}/article_viewer`,
        '_blank',
      )
  }
}
