








import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

import { User as UserModel } from '@technology/accounts-js/dist/types/models/user'
import CustomChip from '~/components/common/CustomChip.vue'
import NoAssigneeChip from '~/components/dashboard/widget/collaborate/common/displays/NoAssigneeChip.vue'

import { initial } from '~/filters'

@Component({
  components: {
    CustomChip,
    NoAssigneeChip,
  },
  filters: {
    initial,
  },
  name: 'dashboard-collaborate-common-content-assignee-display',
})
export default class AssigneeDisplay extends Vue {
  @Prop({
    required: true,
  })
  public assignee: UserModel & object
  @Prop() public groupName?: string

  get displayName() {
    return this.assignee.displayName ?? ''
  }
}
