






import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import Vue from 'vue'

import { empty } from '~/filters'
import config from '~/config'

@Component({
  name: 'dashboard-collaborate-common-content-article-topic-summary-display',
  filters: {
    empty: empty('-'),
  },
})
export default class ArticleTopicSummaryDisplay extends Vue {
  @Prop()
  public task: TaskModel & object
  @Prop()
  public parentTask: TaskModel & object

  get slotProps() {
    return {
      topicSummary: this.topicSummary,
      click: this.handleTopicSummaryClick,
    }
  }

  get topicSummary() {
    return this.parentTask?.articleSummary
  }

  public handleTopicSummaryClick() {
    const collaborateApp = this.$store.myGetters['accounts/app/currentWorkApps'].find(
      app => app.name === config.appName.collaborate,
    )
    collaborateApp && window.open(`${collaborateApp.url}/locate-task/${this.task.id}`, '_blank')
  }
}
