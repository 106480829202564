const constant = {
  workflowStatusColor: {
    created: '#f5a623',
    declined: '#bb000b',
    approved: '#417505',
    onhold: '#9B9B9B',
  },
  storyStatusText: {
    Cancelled: 'CANCELLED',
    Published: 'PUBLISHED',
    Scheduled: 'SCHEDULED',
    Staged: 'STAGED',
  },
  storyColor: {
    isCancelled: '#b74c3b',
    isPublish: '#b8e986',
    isSchedule: '#f5a623',
    isStage: '#90caf9',
  },
  topicStatus: {
    isPurged: 'Purged',
  },
  dateTypes: {
    eventDate: {
      value: 'eventDate',
      text: 'Event Date',
    },
    pubDate: {
      value: 'pubDate',
      text: 'Issue Date',
    },
  },
  calendarTypes: {
    Week: { text: 'Week', value: 'custom-weekly' },
    Month: { text: 'Month', value: 'month' },
  },
}

export default constant
