




















import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import Vue from 'vue'

import AssigneeDisplay from '~/components/dashboard/widget/collaborate/common/displays/AssigneeDisplay.vue'
import CustomChip from '~/components/common/CustomChip.vue'
import NoAssigneeChip from '~/components/dashboard/widget/collaborate/common/displays/NoAssigneeChip.vue'

@Component({
  components: {
    AssigneeDisplay,
    CustomChip,
    NoAssigneeChip,
  },
  name: 'dashboard-collaborate-common-content-assignees-display',
})
export default class AssigneesDisplay extends Vue {
  @Prop() public task: TaskModel & object

  get assignees() {
    return (this.task.assigneeIds || []).map(assigneeId =>
      this.$store.myGetters['accounts/user/getById'](assigneeId),
    )
  }

  get relatedTasks() {
    return this.$store.myGetters.getAllRelatedTasksById(this.task.id).filter(t => !t.isArchived)
  }

  public getGroupName(task: TaskModel) {
    return this.$store.myGetters.getGroupName(task.boardId)
  }

  public getAssignees(task: TaskModel) {
    return task.assigneeIds
      ?.map(assigneeId => this.$store.myGetters['accounts/user/getById'](assigneeId))
      .sort((a, b) => (a.displayName > b.displayName ? 1 : a.displayName < b.displayName ? -1 : 0))
  }
}
